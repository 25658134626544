<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";
import appConfig from "@/app.config.json";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import axios from "axios";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";
import {AgGridVue} from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import PushImageEditor from "@/views/pages/push/pushmsg/modals/pushImageEditor.vue";

export default {
  page: {
    title: "PUSH 알림 전송",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      deviceId: '9876543210',
      title: "PUSH 알림 전송",
      pushCNT: "무제한",
      marketName: "크리톡",
      pushmsg: "",
      previewImg: "",
      pushImg: "",
      noImg: "https://cdn1.critalk.com/static/images/no_image.png",
      openEx: false,
      openCNTguide: false,

      gridApi: null,
      columnApi: null,
      columnDefs: [
        {
          headerName: '#',
          field: 'member_srl',
          checkboxSelection: true,
          headerCheckboxSelection: true,
        },
        {headerName: '고객명', field: 'nick_name'},
        {headerName: '연락처', field: 'phone'}
      ],

      sendingList: [],
      sendingType: "now",
      sendMemberList: "",
      sendMemberCount: 0,

      config: {
        enableTime: false,
        minDate: 'today',
        dateFormat: "Y-m-d",
      },

      resvTime: '13:00',
      resvDate: new Date(),
      timeOptions: {
        enableTime: true,
        noCalendar: true,
        time_24hr: true,
        dateFormat: "H:i"
      },
    };
  },

  components: {
    PushImageEditor,
    Layout,
    PageHeader,
    AgGridVue,
    flatPickr
  },

  computed: {
    ...mapState(loggedInfoObject)
  },

  mounted() {
    //초기화면 설정
    this.initPage()
    // this.callRemainPush()

    this.callTalkMember()
  },

  methods: {
    //Ag-Grid 관련 Methods
    onFirstDataRendered(event) {
      event.api.sizeColumnsToFit()
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.$refs.default_input.value)
    },
    onGridReady(params) {
      this.gridApi = params.api
    },
    getSelectedRowData() {
      this.sendMemberCount = this.gridApi.getSelectedNodes()
      let selectData = this.sendMemberCount.map(node => node.data.member_srl).join('|')
      console.log(selectData, this.sendMemberCount.length)
      // console.log(JSON.stringify(selectData[0].mk_custom_srl))
      // let list = []
      // selectData.forEach(function (item) {
      //   list.push(item.member_srl)
      // })
      // console.log("list", list)
      //
      // this.sendMemberList = list.map(item => item.member_srl).join('|');
      // console.log(this.sendMemberList)

      return selectData
    },

    callTalkMember() {
      /*let url = "/api/kakao/custom/306"*/

      let url = `/api/member/admin/getListAllMemberByFilter/1/9999999`

      let data = {
        market_srl: Number(this.logged_info.mart_srl),
      }

      axios.post(url, data)
          .then((response) => {
            console.log(response.data)
            this.sendingList = response.data.member_info_list
          })
          .catch((err) => {
            console.log(err)
          })
    },

    //초기화면 설정
    initPage() {
      this.marketName = this.logged_info.mart_name
      this.pushImg = ""
      this.pushmsg = ""
      this.previewImg = ""
      this.openEx = false
    },

    //파일선택시 이미지 미리보기 처리 및 이미지파일을 변수에 넣어두기
    previewImage() {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          //이미지 미리보기 변수에 이미지 넣기
          this.previewImg = e.target.result
          //이미지 파일을 변수에 넣어두기
          this.pushImg = input.files[0]
          console.log(this.pushImg)
        };
        reader.readAsDataURL(input.files[0]);
      } else {
        this.pushImg = null;
      }
    },

    //날짜 formating
    leftPad(value) {
      if (value >= 10) {
        return value;
      }
      return `0${value}`
    },

    //날짜 formating
    toStringByFormatting(select, source) {
      //console.log(source)
      //console.log(new Date().toJSON())
      const year = source.getFullYear();
      const month = this.leftPad(source.getMonth() + 1);
      const day = this.leftPad(source.getDate());

      let hour = source.getHours()
      hour = hour >= 10 ? hour : '0' + hour
      let min = source.getMinutes()
      min = min >= 10 ? min : '0' + min
      let sec = source.getSeconds()
      sec = sec >= 10 ? sec : '0' + sec

      let time = ''

      if (select === 'dateOnly') {
        time = `${year}-${month}-${day}`
      } else if (select === 'link') {
        time = `${year}${month}${day}`
      } else {
        time = `${year}-${month}-${day} ${hour}:${min}:${sec}`
      }

      console.log("TIME :", time)

      return time
    },

    //전송 시간 선택하기
    selectSendTime() {
      console.log("sending type :", this.sendingType)
      if (this.sendingType === 'now') {
        // this.tDate = this.toStringByFormatting('link', new Date())
        return this.toStringByFormatting("else", new Date())
      } else {
        try {
          // this.tDate = this.toStringByFormatting('link', new Date(this.resvDate))
          return this.toStringByFormatting('dateOnly', new Date(this.resvDate)) + " " + this.resvTime + ":00"
        } catch {
          return this.resvDate + ' ' + this.resvTime + ':00'
        }
      }
    },

    //푸시 발송
    submitPush() {
      if (this.pushmsg === '') {
        this.$bvModal.msgBoxOk("PUSH 내용이 공백입니다.", msgModalOptCenter)
        return
      }

      this.$bvModal.msgBoxConfirm("푸시메시지는 발송시 취소 및 수정이 되지 않습니다.\n바로 발송하시겠습니까?", msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              console.log(this.pushImg)
              const fd = new FormData()

              fd.append("comment", this.pushmsg)
              fd.append("cur_cnt", this.sendMemberCount)
              fd.append("mart_srl", this.logged_info.mart_srl)
              fd.append("receiver", this.getSelectedRowData())
              fd.append("rsrv_time", this.selectSendTime())
              fd.append("title", this.logged_info.mart_name)
              fd.append("img", this.pushImg)

              axios.post(`/api/push/dag`, fd)
                  .then((response) => {
                    console.log(response.data)
                    if (response.data.status === "success") {
                      this.$bvModal.msgBoxOk("푸시메시지가 성공적으로 발송되었습니다.", msgModalOptCenter)

                      this.gridApi.deselectAll()

                      this.initPage()
                      // this.callRemainPush()
                    } else {
                      this.$bvModal.msgBoxOk("푸시메시지 발송에 실패하였습니다.\n다시 시도해주세요.", msgModalOptCenter)
                    }
                  })
                  .catch(error => {
                    console.log(error)
                  })
            }
          })
    },

    //최고 노이미지 이미지의 Url 설정
    urlEncoding(url) {
      return encodeURI(url)
    },

    //푸시메시지 예문 보여줄지 여부
    showEx() {
      this.openEx = !this.openEx
    },
    //푸시메시지 남은횟수에 대한 가이드를 보여줄지 여부
    showGuide() {
      this.openCNTguide = !this.openCNTguide
    },
    //남은 푸시메시지 횟수
    // callRemainPush() {
    //   let url = `/api/push/${this.logged_info.mart_srl}`
    //
    //   axios.get(url)
    //       .then((res) => {
    //         console.log(res.data)
    //         if (res.data.cnt == '-1') {
    //           this.pushCNT = '무제한'
    //         } else {
    //           this.pushCNT = res.data.cnt + '건'
    //         }
    //       })
    //       .catch(err => console.log(err))
    // },

    getPreviewImage(val) {
      this.previewImg = val.previewImage
      this.pushImg = val.selectImage
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="row">
      <div class="col-xl-5">
        <div class="card">
          <div class="card-body">
            <div>
              <h5 style="margin: 0;">발송 시간 선택</h5>
            </div>
            <hr class="my-3"/>

            <div style="padding-bottom: 15px;">
              <div
                  style="display: flex; align-items: center; box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2); height: 50px; border-radius: 0.25rem;">
                <div class="form-check w-100" style="margin: 14px;">
                  <input
                      class="form-check-input"
                      type="radio"
                      name="send"
                      id="send1"
                      v-model="sendingType"
                      value="now"
                      checked
                  />
                  <label class="form-check-label w-100" for="send1">
                    즉시발송
                  </label>
                </div>
              </div>
            </div>

            <div
                style="display: flex; flex-direction: column; justify-content: center; box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2); border-radius: 0.25rem;">
              <div class="form-check" style="margin: 14px;">
                <input
                    class="form-check-input"
                    type="radio"
                    name="send"
                    id="send2"
                    value="res"
                    v-model="sendingType"
                />
                <label class="form-check-label w-100" for="send2">
                  예약발송
                </label>
              </div>

              <div v-if="sendingType === 'res'" style="margin-top: 0; padding: 14px; background-color: #f3f3f3">
                <table class="w-100">
                  <thead>
                  <tr>
                    <td style="width: 50%;">
                      <feather type="calendar" style="width: 14px; height: 14px; margin-right: 6px"/>
                      날짜
                    </td>
                    <td>
                      <feather type="clock" style="width: 14px; height: 14px; margin-right: 6px"/>
                      시간
                    </td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <flat-pickr
                          v-model="resvDate"
                          :config="config"
                          placeholder="발송일"
                          class="form-control"
                      ></flat-pickr>
                    </td>
                    <td>
                      <flat-pickr
                          v-model="resvTime"
                          :config="timeOptions"
                          class="form-control"
                      />
                      <!--<input type="text" class="form-control timepicker" id="resrv_time" name="resrv_time" placeholder="시간" onkeypress="checkInputTime(event);" onfocus="blur();">-->
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="row" style="display: flex; align-items: center; margin-bottom: -10px;">
              <div class="col-lg-7" style="display: inline-block; vertical-align: middle; float: none;">
                <h5 style="margin: 0; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                  수신자 설정
                  <!--                ({{ groupName }} : {{ talkList.length }})-->
                </h5>
              </div>
              <div class="col-lg-5">
                <input
                    class="form-control"
                    type="text"
                    ref="default_input"
                    placeholder="수신자 검색"
                    @input="onFilterTextBoxChanged()"
                />
              </div>
            </div>
            <hr class="my-3"/>
            <div style="width: 100%">
              <ag-grid-vue
                  style="height: 50vh; width: 100%; text-align: center;"
                  class="ag-theme-alpine"
                  :columnDefs="columnDefs"
                  :rowData="sendingList"
                  :cacheQuickFilter="true"
                  @grid-ready="onGridReady"
                  @first-data-rendered="onFirstDataRendered"
                  rowSelection="multiple"
                  suppressRowClickSelection="true"
                  suppressHorizontalScroll="true"
                  enableCellTextSelection="true"
              ></ag-grid-vue>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-5">
        <!-- 기본정보 영역 -->
        <div class="card">
          <div class="card-body">
            <div>
              <h5 style="margin: 0;">발송 내용 작성</h5>
            </div>
            <hr class="my-3"/>

            <div v-if="false" style="position: relative; padding-bottom: 14px">&nbsp;
              <label class="text-muted mb-1" style="position: absolute; right:0; margin-right: 10px">
                남은전송건수 : {{ this.pushCNT }}
                <!-- 남은 횟수에 대한 설명보기 버튼 -->
                <i class="uil-question-circle" @click="showGuide"></i></label>
              <!-- 남은 횟수 가이드 -->
              <p style="position: absolute; right:0; font-size: 0.7rem; color: #7E93A0" v-if="openCNTguide">남은 건수는
                하루의 남은 푸시 횟수입니다.</p>
            </div>

            <!-- 최초페이지 이미지가 등록되지 않았을 때 보여줄 이미지 -->
            <div style="width: 100%; border: thin solid #e2e5e8; border-radius: 0.25rem" v-b-modal="'image-edit'">
              <b-img
                  :src="previewImg.length === 0 ? `${urlEncoding(noImg)}` : previewImg"
                  fluid
                  alt="Responsive image"
                  style="display: block; margin: 0 auto; height: 320px;"
              ></b-img>
            </div>

            <!-- 이미지 미리 보기 영역 -->
            <!--                <div v-if="previewImg.length > 0">-->
            <!--                  <div style="width: 100%; border: thin solid #a2a2a2;">-->
            <!--                    <b-img-->
            <!--                        :src="previewImg"-->
            <!--                        alt="Responsive image"-->
            <!--                    ></b-img>-->
            <!--                  </div>-->
            <!--                </div>-->
            <div class="col-12 mt-3" v-if="false">
              <input class="form-control" type="file" id="formFile" @change="previewImage"
                     accept=".jpg, .png, .gif"/>
            </div>
            <h5 class="text-muted mb-1" style="margin-top: 30px; font-weight: bold">제목(업체명)</h5>
            <!-- 푸시메시지 제목(업체명이 들어가고 수정불가능) -->
            <div class="col-md-12" style="margin-top: 12px">
              <input
                  class="form-control"
                  type="text"
                  v-model="marketName"
                  style="background-color: #dbdbdb;"
                  readonly
              />
            </div>
            <h5 class="text-muted mb-1" style="margin-top: 30px; font-weight: bold">내용
              <!-- 예시문보기버튼 -->
              <i class="uil-question-circle" @click="showEx"></i></h5>
            <!-- 내용입력란 -->
            <div class="col-md-12" style="margin-top: 12px">
              <b-textarea
                  class="form-control"
                  type="text"
                  v-model="pushmsg"
                  id="pushcontext"
                  style="height: 200px"
              />
            </div>
            <!-- 푸시메시지 예시부분 -->
            <div style="padding-left: 30px; margin-top: 8px" v-if="openEx">
              <label>🚀예시 TIP</label><br>
              <label>감사대전 특가행사! 0월00일 ~ 0월 00일</label><br>
              <label>감사함으로 행사를 준비했습니다.</label><br>
              <label>앞으로 더 만족시켜드리는 저희 {{ marketName }}(이)가 되겠습니다.</label><br>

            </div>
            <!-- 발송버튼 -->
            <div class="col-12" style="margin-top: 20px">
              <b-button variant="primary" style="width: 100%" @click="submitPush">
                푸시메시지발송
              </b-button>
            </div>


          </div>
        </div>
        <!-- end card -->
        <!-- 기본정보 영역 End-->
      </div>

    </div>

    <push-image-editor @sendingImageData="getPreviewImage"></push-image-editor>
  </Layout>
</template>

<style lang="scss" scoped>
.ag-theme-alpine::v-deep {
  .ag-header-cell-label {
    justify-content: center;
  }

  .ag-cell {
    //border-right: 1px solid !important;
    border-right: 0 !important;
    border-top: 0 !important;
    //border-color: #f4f4f4 !important;
  }
}
</style>
